var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看杆位",
            visible: _vm.dialogVisible,
            width: "500px",
            "show-close": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: {
                model: _vm.formInline,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "杆位号:", prop: "barCode" } },
                [_c("span", [_vm._v(_vm._s(_vm.formInline.barCode))])]
              ),
              _c("el-form-item", { attrs: { label: "经纬度:", prop: "" } }, [
                _c("span", [_vm._v(_vm._s(this.lng))]),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "是否自有杆:", prop: "own" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.formInline.own === 0 ? "是" : "否")),
                  ]),
                ]
              ),
              _c("el-form-item", { attrs: { label: "视频设备:", prop: "" } }, [
                _c(
                  "div",
                  { staticClass: "listStyleWrapper" },
                  [
                    _vm._v(" " + _vm._s(_vm.videoList.equipmentName) + " "),
                    _vm._l(_vm.videoList, function (v) {
                      return _c(
                        "div",
                        { key: v.equimentId, staticClass: "listStyle" },
                        [_vm._v(_vm._s(v.equipmentName))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("el-form-item", { attrs: { label: "监控设备:", prop: "" } }, [
                _c(
                  "div",
                  { staticClass: "listStyleWrapper" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.monitor.monitorEquipmentName) + " "
                    ),
                    _vm._l(_vm.monitor, function (v) {
                      return _c(
                        "div",
                        { key: v.monitorEquipmentId, staticClass: "listStyle" },
                        [_vm._v(_vm._s(v.monitorEquipmentName))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }